import React, {Component, useState} from 'react'
import TouchCarousel, { clamp } from "react-touch-carousel";
import cx from "classnames";
import data from './Data-1';
import NonPassiveTouchTarget from '../../NonPassiveTouchTarget';
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";

const cardSize = 100;
const cardPadCount = 3;
const carouselWidth = clamp(window.innerWidth, 0, 960);

function CarouselContainer(props) {
    const {
      cursor,
      carouselState: { active, dragging },
      ...rest
    } = props;
    let current = -Math.round(cursor) % data.length;
    while (current < 0) {
      current += data.length;
    }
    // Put current card at center
    const translateX =
      (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2;
    return (
      <NonPassiveTouchTarget
        className={cx("carousel-container-clientes", {
          "is-active": active,
          "is-dragging": dragging
        })}
      >
        <NonPassiveTouchTarget
          className="carousel-track"
          style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
          {...rest}
        />
  
        <div className="carousel-pagination-wrapper">
          <ol className="carousel-pagination">
            {data.map((_, index) => (
              <li key={index} className={current === index ? "current" : ""} />
            ))}
          </ol>
        </div>
      </NonPassiveTouchTarget>
    );
  }

  const Container = touchWithMouseHOC(CarouselContainer);
  
class CarrouselPrincipal extends Component {
      
    renderCard(index, modIndex) {
        const item = data[modIndex];
        return (
          <div
            key={index}
            className="carousel-card-clientes"
          >
            <div
              className="carousel-card-inner-clientes"
              style={{ backgroundColor: item.background }}
            >
              <div className="carousel-img">
                <img src={item.img} alt="Img" />  
              </div>
            </div>
          </div>
        );
      }

      render() {
        return (

                    <TouchCarousel
                        component={Container}
                        cardSize={cardSize}
                        cardCount={data.length}
                        cardPadCount={cardPadCount}
                        loop={true}
                        autoplay={this.props.tempo}
                        renderCard={this.renderCard}
                    />
        )
      }
   
}

export default CarrouselPrincipal