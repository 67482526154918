import React from 'react'

const Ebackup = () => {
    return (
        <div className="Ebackup" >
            <div className="content-ebackup">
                <div className="title-ebackup">
                    <h1>E-Backup</h1>
                    <div className="content-descricao-title-ebackup">
                    <p>Os serviços gerenciados do e-backup proporcionam segurança e controle aos seus arquivos com monitoração constante do seu backup <br /> e preserva a confidencialidade por meio de criptografia.</p>
                    </div>
                </div>
                <div className="content-img-title-ebackup">
                <img src="https://res.cloudinary.com/kassio-company/image/upload/v1634239762/E-Tech/Diversos/01_gnb4gc.png" alt="E-Backup E-Tech" className="img-title-ebackup" />
                </div>

                <div className="border-portal"></div>

                <div className="solucao-ebackup">
                    <div className="title-solucao-ebackup">
                        <h1>Procurando uma solução de backup baseado em nuvem, <br />  fácil de implantar e Gerenciar?</h1>
                        <p>De forma rápida e fácil para que você possa implantar e gerenciar tudo a partir de uma console na nuvem, que fornece controle de acesso baseado em regras. O serviço escala facilmente por grupos adicionados e políticas para as novas divisões, escritórios e departamento.</p>
                    </div>
                    <div className="content-img-solucao-ebackup">
                    <img src="https://res.cloudinary.com/kassio-company/image/upload/v1634239762/E-Tech/Diversos/02_rqpa0a.png" alt="Icone E-Backup E-Tech" />
                    </div>
                </div>

                <div className="border-portal"></div>
                
                <div className="topicos-ebackup">
                    <div className="content-box-topico-ebackup">
                    <div className="box-topico-ebackup">
                        <h1>Backup</h1>
                        <p>De servidores físicos, virtuais e PCs para o seu <br className="mobile" /> armazenamento local ou para os Datas Centers Seguros.</p>
                    </div>

                    <div className="box-topico-ebackup">
                        <h1>Recuperação</h1>
                        <p>Recupera arquivos individuais, discos rígidos e sistemas <br className="mobile" /> inteiros de forma rápida e fácil.</p>
                    </div>

                    <div className="box-topico-ebackup">
                        <h1>Tecnologia</h1>
                        <p>Interface web de fácil utilização, reduzindo <br className="mobile" /> a carga de trabalho de sua equipe de TI.</p>
                    </div>
                    </div>
                    <div className="content-img-solucao-ebackup">
                    <img src="https://res.cloudinary.com/kassio-company/image/upload/v1634239762/E-Tech/Diversos/03_b8aaep.png" alt="Icone E-Backup E-Tech" />
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Ebackup
