import React from "react";
import { Link } from "react-router-dom";

const Bematech = () => {
    return (
        <div>

<div className="Info">
        <div className="contentInfo">
            <div className="sobreInfo">
                <div className="boxInfo">
                    <div className="infoboxInfo">
                        <h1>Sobre a SGBR</h1>
    
                        <p>CONHEÇA A EMPRESA
“S” de Sistema, “G” de gerencial e “Br” de Brasil, o significado das letras fazem jus ao nosso compromisso em atender todo o país. O projeto surge em 2010 com um sistema capaz de atender todos os estados brasileiros e devidamente homologado nas secretarias estaduais da fazenda. Ao término de 2012 a SGBr® finaliza sua primeira solução em software, o SG Master, e inicia o processo de homologação, obtendo seu laudo técnico em conformidade com a legislação fiscal brasileira.
Nossos ramos de atuação são comércios em geral, prestadores de serviços, bares, restaurantes, hotéis, pequenas e médias transportadoras, farmácias e drogarias.
                        </p>
                    </div>
                </div>
            </div>

            <div className="imgInfo">
                <div className="imgInfo-01">
                    <img src="https://sgbr.com.br/assets/site/img/pc-box.webp" alt="Imagem SGBR" />
                </div>
    
                <div className="imgInfo-02">
                    <img src="https://i.ytimg.com/vi/jbbcYavY084/maxresdefault.jpg" alt="Imagem SGBR" />
                </div>
            </div>
        </div>

        <div className="videoInfo">
            <iframe width="700" height="450" src="https://www.youtube.com/embed/wV7TtRnjHSs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div className="infoVideo">
            <div className="boxInfoVideo">

                <h1>Mais informações</h1>

                <p>Nossos ramos de atuação são comércios em geral, prestadores de serviços, bares, restaurantes, hotéis, pequenas e médias transportadoras, farmácias e drogarias. Contamos com atendimento diferenciado, 24 horas por dia, 7 dias da semana. Tudo isso visando melhor atender nossos parceiros e clientes, contando com uma equipe especializada e humanizada, gerando satisfação e oportunidades. Especializada em automação comercial, a SGBR® Sistemas fornece ao mercado soluções inovadoras em software para micro, pequenas e médias empresas do Brasil.</p>
            </div>
        </div>

        <div className="infoTutorial">
            <div className="boxInfoTutorial">
                <h1>Quer dar uma aprofundada maior nos equipamentos da SGBR ? <br />
                    <Link href="/Dashboard/Download" to="/Dashboard/Download">
                    <a>Veja alguns tutorias</a>
                    </Link>
                    </h1>

                <div className="tutorialInfo">
                    <div className="videoTutoriaInfo-1">
                        <iframe width="350" height="250" src="https://www.youtube.com/embed/5b39jL71fhg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>

                    <div className="videoTutoriaInfo-2">
                        <iframe width="350" height="250" src="https://www.youtube.com/embed/DxKlcp3GuJE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>

                <div className="buttonTutorialInfo">
                    <i className="fas fa-eye"></i>
                    <Link href="/Dashboard/Download" to="/Dashboard/Download">
                    <a>Acessar tutoriais</a>
                    </Link>
                </div>
            </div>
        </div>

        <div className="infoSolicitar">
            <div className="boxinfoSolicitar">
                <div className="infoSolicitarAgora">
                    <i className="fas fa-shopping-cart"></i>
                    <Link to="/Solicitar">
                            <a>Solicitar agora</a>
                            </Link>
                </div>
            </div>
        </div>
    </div>

        </div>
    )
}

export default Bematech;