import React from 'react';
import { Link } from 'react-router-dom';

const Periferico = () => {
    return (
        <div className="parceiros" >
            <div className="contentParceiros">

          <div className="classesParceiros">
          <i class="fas fa-print"></i>
  <h1>Periféricos</h1>
</div>

<div className="contentBoxParceiro">

  <div className="parteBoxParceiro">

    <div className="boxParceiros">
      <Link to="Empresas/Compufour">
      <i class="fas fa-desktop"></i>
      </Link>
    </div>

    <div className="boxParceiros">
      <Link to="Empresas/Bematech">
         <img src="https://res.cloudinary.com/kassio-company/image/upload/v1629832616/E-Tech/Diversos/Leitor.png" alt="Leitor Código De Barras" />
      </Link>
    </div>

    <div className="boxParceiros">
      <Link to="Empresas/Gdoor">
       <img src="https://res.cloudinary.com/kassio-company/image/upload/v1629832541/E-Tech/Diversos/Impressora.png" alt="Impressora Não Fiscal" />
      </Link>
    </div>

    <div className="boxParceiros">
      <Link to="Empresas/Digisat">
         <img src="https://res.cloudinary.com/kassio-company/image/upload/v1629832433/E-Tech/Diversos/Pinpad.png" alt="Pin Pad" />
      </Link>
    </div>
  </div>


          </div>
          
          <div className="content-prox-parceiros">
            <div className="prox-parceiros-button">
                <Link to="/Parceiros/Certificados" >
                Certificados Digitais
              </Link>
              <i class="fas fa-chevron-right"></i>
              </div>
          </div>

</div>
        </div>
    )
}

export default Periferico
