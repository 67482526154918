import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import Teamviewer from '../download/TeamViewer_Setup_pt.exe';
import IBManager from '../download/IBManager.exe';

const Download = () => {

    document.body.scrollTop = 0;

    return (
        <div>

        {localStorage.getItem("token-acesso", "Cd9De0QvsL1/zbKju7MvFnIwGjnFwcjivnDaT6jr/k9EsA6B8sCiOwb5nWes3m4NRyjrMA2Jqhlp/czKvK4n4g==") ? null : <Redirect to="/Login" />}

<main class="downloadAdmin">

<div class="contentDownloadAdmin">
    <div class="titleDownloadAdmin">
        <h1>Baixar Arquivos</h1>
        <p>Para baixar algum destes arquivos basta clicar no botão de baixar</p>
    </div>

    {/*Acesso Remoto*/}

    <div class="contentClassesDownloadAdmin">
        <div class="classeBoxDownloadAdmin">
            <h1>Programas</h1>
        </div>
    </div>

    <div class="contentBoxDownloadAdmin">

        <div class="parteBoxDownloadAdmin">
            
            <div class="boxDownloadAdmin">
                                <img src="https://res.cloudinary.com/kassio-company/image/upload/v1633113786/E-Tech/Diversos/teamviewer.png" alt="TeamViewer" />
                                <h1>Team Viewer</h1>
                                <p>O TeamViewer é um pacote de software proprietário para acesso remoto, compartilhamento de área de trabalho, conferência online e transferência de arquivos entre computadores.</p>
                                <div className="button-box-download">
                                    <Link to={Teamviewer} download >Baixar</Link>
                                </div>
            </div>

            <div class="boxDownloadAdmin">
                                <img src="https://res.cloudinary.com/kassio-company/image/upload/v1633113785/E-Tech/Diversos/anydesk.png" alt="Any desk" />
                                <h1>Any desk</h1>
                                <p>O TeamViewer é um pacote de software proprietário para acesso remoto, compartilhamento de área de trabalho, conferência online e transferência de arquivos entre computadores.</p>
                                <div className="button-box-download">
                                    <Link to="https://download.anydesk.com/AnyDesk.exe?_ga=2.35228643.376565920.1633115044-1516672683.1633115044" download >Baixar</Link>
                                </div>
            </div>

            <div class="boxDownloadAdmin">
                                <img src="https://res.cloudinary.com/kassio-company/image/upload/v1633114540/E-Tech/Diversos/firebird.png" alt="Firebird" />
                                <h1>IB Manager</h1>
                                <p>O TeamViewer é um pacote de software proprietário para acesso remoto, compartilhamento de área de trabalho, conferência online e transferência de arquivos entre computadores.</p>
                                <div className="button-box-download">
                                    <Link to={IBManager} download >Baixar</Link>
                                </div>
            </div>
           
        </div>


    </div>

</div>

</main>

        </div>
    );
}

export default Download;