const data = [
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816624/E-Tech/Logos/carne-assada_1_jh1xjs.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816601/E-Tech/Logos/calce-bem_gboxpi.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816572/E-Tech/Logos/supermercado-andreia_mieqrz.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816571/E-Tech/Logos/tunes_bru346.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816571/E-Tech/Logos/destak_frde1z.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816523/E-Tech/Logos/marcio-m%C3%B3veis_citv9o.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816521/E-Tech/Logos/sabor-caseiro_kaocih.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816521/E-Tech/Logos/panificadora-st-antonio_zsll01.png"
  },   
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816079/E-Tech/Logos/gts_poeggx.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816813/E-Tech/Logos/Nacional_GAS_iyzn1t.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816803/E-Tech/Logos/Supermercado_Costal_Azul_kezxdb.png"
  },
  {
    img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816801/E-Tech/Logos/BrasilGas_mgba1e.png"
},
{
img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816519/E-Tech/Logos/o-barateiro_rtmyv1.png"
},
{
img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816518/E-Tech/Logos/mercantil-seabra_rjscui.png"
},
{
img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816518/E-Tech/Logos/mercado-queiroz_muuoon.png"
},
{
img: "https://res.cloudinary.com/kassio-company/image/upload/v1631816517/E-Tech/Logos/mercado-medeiros_ssxcya.png"
},
]

export default data;