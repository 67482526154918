import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Navbar = () => {
  
  const [isMobile, setIsMobile] = useState(false);

  const [isMenu, setIsMenu] = useState(false);

  const [popUp, setPopUp] = useState(false)


  return (
    <div className="nav-page" >
      <nav className="navbar">

        {popUp === true ? <Popup open onClose={() => setPopUp(false)} position="center center" >
                    <div className="popup-portal">

                        <div className="title-popup-portal">
                            <h1>Comunicado</h1>
                            <p>As informações constantes na plataforma são provenientes dos arquivos processados pelas empresas utilizando os sistemas que as geram. Poderá haver divergências por se tratar de uma aplicação coletora de informações diretamente do software local tais como:</p>
                        </div>

                        <div className="lista-popup-portal">
                            <p>- Emissão de NFCE's em contingência.</p>
                            <p>- Emissão de NFE's sem autorização.</p>
                            <p>- Guarda de NFE's de entrada em locais divergentes ao sistema.</p>
                        </div>

                        <div className="aviso-final-popup-portal">
                            <p>Comprovado a existência de divergências, favor entrar em contato diretamente com o Emitente para resolução das mesmas.</p>
                        </div>

                        <div className="button-popup-portal">
                            <a href="http://portal.etechseabra.com.br/#/" target="_blank" >
                                Clique para prosseguir
                            </a>
                        </div>

                    </div>
                </Popup> : null}

            <Link to="/" className="logo">
                <img src="https://res.cloudinary.com/kassio-company/image/upload/v1628526099/E-Tech/Logo.png" alt="Logo" />
            </Link>

            <ul className="nav-links" >

                <Link to="/" className="homeNavbar" >
                    <i class="fas fa-home-lg-alt mobileDisabled"></i>
                    <li>Home</li>
                </Link>

                <Link to="/Solicitar" className="button-ebackup" >
                    <div className="boxPortal">
                      <li>Solicitar agora!</li>
                    </div>
                </Link>

                <Link to="#" onClick={() => setPopUp(true)} >
                    <div className="boxLoginNavbar">
                       <li>Login</li>
                    </div>
                </Link>

            </ul>

            <button className="mobile-menu-icon" onClick={() => {setIsMobile(!isMobile)}}>
                {isMobile ? (
                <i class="fas fa-times"></i>
                ) : (
                <i class="fas fa-bars"></i>
                )}
            </button>

        </nav>

          <ul className={isMobile ? "nav-links-mobile" : "disabled"}>
          <div className="content-menu-mobile">


            <Link to="/" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-home"></i>
              Home
              </li>
            </Link>

            <Link to="/Parceiros/Solucoes" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-desktop"></i>
              Soluções
              </li>
          </Link>
          
          <Link to="/Parceiros/Perifericos" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-print"></i>
              Periféricos
              </li>
          </Link>
          
          <Link to="/Parceiros/Certificado" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-certificate"></i>
              Certificados
              </li>
          </Link>
          
          <Link to="/Sobre" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-user"></i>
              Sobre
              </li>
          </Link>

          <Link to="/Solicitar" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-comments-dollar"></i>
              Solicitar
              </li>
            </Link>
          
          <Link to="/Contato" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-envelope"></i>
              Contato
              </li>
            </Link>

            <a href="https://us-cloud.acronis.com/login" target="_blanck" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-cloud"></i>
                E - Backup
                </li>
          </a>
          
          <Link to="/Portal" target="_blanck" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-portrait"></i>
              Portal do Contador
              </li>
            </Link>

            <Link to="/Login" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile">
              <i class="fas fa-user"></i>
              Login
              </li>
            </Link>

            </div>
          </ul>

          
              
        </div>
  );

}

export default Navbar;