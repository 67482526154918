import React, { useContext, useState } from 'react';
import { useAlert } from "react-alert";
import { Link, Redirect } from 'react-router-dom';
  
  const Login = () => {

    const [ email, setEmail ] = useState('');
    const [senha, setSenha] = useState('');
    
    const [ error, setError ] = useState(false);

    document.body.scrollTop = 0;

    const data = new Date();


    return (
      <div className="login" >

        {localStorage.getItem("token-acesso", "Cd9De0QvsL1/zbKju7MvFnIwGjnFwcjivnDaT6jr/k9EsA6B8sCiOwb5nWes3m4NRyjrMA2Jqhlp/czKvK4n4g==") ? <Redirect to="/Download" /> : <Redirect to="/Login" />}

        <div className="content-input">

          <div className="title-input-login">
            <h1>Login</h1>
            <p>Preencha os campos para iniciar sua sessão</p>
          </div>

          <div className="border-login"></div>

          <div className="input-login">
            
            {
              error === false ?
                
              <div className="content-input-login">
              
              <p className="text-input-login">Email</p>
              <input type="email" placeholder="ex: cliente@etech.com.br" onChange={e => setEmail(e.target.value)} />
                  
              </div>
                
                :

                <div className="content-input-login">
                  <div className="error-input-login">
                  <i class="fas fa-exclamation-triangle"></i>
                  <p className="error-login" >Email Inválido</p>
                  </div>
                <input type="email" placeholder="ex: cliente@etech.com.br" onChange={e => setEmail(e.target.value)} />
              </div>
            }

            {
              error === false ?
                
              <div className="content-input-login">
              
              <p className="text-input-login" >Senha</p>
              <input type="password" placeholder="ex: 123456" onChange={e => setSenha(e.target.value)} />
                  
              </div>
                
                :

                <div className="content-input-login">
                  <div className="error-input-login">
                  <i class="fas fa-exclamation-triangle"></i>
                  <p className="error-login" >Senha Inválido</p>
                  </div>
                <input type="password" placeholder="ex: 123456" onChange={e => setSenha(e.target.value)} />
              </div>
            }

          </div>

    <div className="content-button-login">
                  <div className="button-login">


                  { email === "admin@etech.com.br" && senha === "admin@etech.com.br" ?  
                  

                  <div>

                    <Redirect to="/Download" />

                    <Link to="/Download" >
                      Login
                    </Link>

                    {localStorage.setItem("token-acesso", "Cd9De0QvsL1/zbKju7MvFnIwGjnFwcjivnDaT6jr/k9EsA6B8sCiOwb5nWes3m4NRyjrMA2Jqhlp/czKvK4n4g=="  )}

                  </div>

                  :
               
                  <Link to="/Login" onClick={() => setError(true)}  >
                    Login
                  </Link>
                  
                  }
                  </div>
              </div>

        </div>

      </div>
    );
};

export default Login;