import React, { useState } from 'react';
import { useAlert } from "react-alert";
import { Link } from 'react-router-dom';

const Footer = () => {

  const [nome, setNome] = useState('');
  const [motivo, setMotivo] = useState('');

  document.body.scrollTop = 0;

  const alert = useAlert();

    return (
            <div className="content-page-footer">
              <div className="footer">
                <div className="item">
                  <h1 className="title-footer">E - Tech</h1>
                  <p>O Grupo E-TECH destaca-se entre as maiores e mais completas empresas <br />
                    de automação comercial de Seabra-BA, oferecendo as melhores soluções <br />
                    do mercado. Tudo aliado a experiência, conhecimento, tradição, qualidade <br />
                    nos serviços prestados.</p>
                </div>

                <div className="itemLink">
                  <h1>Navegação</h1>
                  <div className="contentItemLink">
                  <Link to="/#parceiros">Parceiros</Link>
                  <Link to="/Login">Dashboard</Link>
                  <Link to="/Contato">Contato</Link>
                  </div>
                </div>

                <div className="itemLink-2">
                  <h1 className="contato-footer" >Contato</h1>
                  <div className="contentItemLink">
                  <div className="box-social-media-footer">
                    <i class="fab fa-instagram"></i>
                    <a href="https://www.instagram.com/etechseabra/" target="_blank">Instagram</a>
                  </div>
                  <div className="box-social-media-footer">
                    <i class="fab fa-whatsapp"></i>
                    <a href="https://api.whatsapp.com/send?phone=5575999553626&text=Ol%C3%A1%2C%20tudo%20bem%3F" target="_blank">Whatsapp</a>
                  </div>
                  <div className="box-social-media-footer">
                    <i class="fab fa-facebook"></i>
                    <a href="https://www.facebook.com/Etechseabra" target="_blank">Facebook</a>
                  </div>
                  <div className="box-social-media-footer">
                  <i class="fas fa-envelope"></i>
                    <Link to="/Contato">
                      Contato
                    </Link>
                  </div>
                  </div>
                </div>
              </div>

              <div className="border-footer"></div>



              <div className="footer-tecnologias">
                <div className="content-footer-tecnologias">

                <div className="tecnologias-footer">

<div className="input-tecnologias-footer">
  <div className="content-input-tecnologias-footer">
  <div className="title-tecnologias-footer-input">
    <h1>Entre em contato conosco</h1>
</div>

<div className="content-input-footer">
  <div className="input-footer">
    <input onChange={(e) => setNome(e.target.value)} type="text" placeholder="Nome" />
    <input onChange={(e) => setMotivo(e.target.value)} type="text" placeholder="Motivo" />
    {motivo === '' ? 
    <div className="content-button-send-input-footer">
      <Link to="#" onClick={() => alert.show("Preencha o formulário corretamente")} ><i class="fas fa-paper-plane"></i></Link>
    </div>
    :
    <Link to={`https://api.whatsapp.com/send?phone=5575999553626&text=Motivo: ${motivo}%0ANome: ${nome}%0A%0A*Mensagem enviada pelo site da E-Tech*`} target="_blank" ><i class="fas fa-paper-plane"></i></Link> }
  </div>
</div>
  </div>
</div>


  <div className="title-tecnologias-footer">
    <h1>Tecnologias</h1>
  </div>
  <div className="content-img-footer">
    <div className="box-img-footer-1">
      <img src="https://www.sacfiscal.com.br/images/graphic1.png" alt="Sac Fiscal" />
    </div>
    <div className="box-img-footer-2">
      <img src="https://res.cloudinary.com/kassio-company/image/upload/v1634577623/E-Tech/Empresas/icone_onyx_sistemas_font_continuum_f7k7er.png" alt="Onyx Sistemas" />
    </div>
    <div className="box-img-footer-3">
      <img src="https://res.cloudinary.com/kassio-company/image/upload/v1634577623/E-Tech/Empresas/wAtende_logo_003_axba2v.png" alt="wAtende" />
    </div>
  </div>
</div>

<div className="footer-endereco">
<div className="endereco-footer-tecnologias">
                 
                 <p><strong>E-Tech Automação Comercial</strong></p>
                <p><strong>Endereço:</strong> Rua Simpliciano Lima, 481-B, Seabra - BA, Centro, Sala 1</p>
                  <p><strong>Tel:</strong> (75) 3331 3626</p>
                  <p><strong>E-mail:</strong> contato@etechseabra.com.br</p>
                  <p><strong>CNPJ:</strong> 13.563.696/0001-06</p>

                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3896.5202713061403!2d-41.77502028561241!3d-12.41501645223913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x769d751b2b4f4b5%3A0x6286b6d66422a78b!2sE-TECH%20Automa%C3%A7%C3%A3o%20Comercial!5e0!3m2!1spt-BR!2sbr!4v1634582330796!5m2!1spt-BR!2sbr" width="450" height="250" style={{ marginTop: "3%" }} allowfullscreen loading="lazy"></iframe>

                </div>

</div>
                  
                </div>
              </div>
            

            </div>
    );
}

export default Footer;