import React, { useState } from 'react';

//Lines

import Line1 from './Box/Line-1/Line-1';
import Line2 from './Box/Line-2/Line-2';


const Clientes = () => {

    const [tempo, setTempo] = useState(2000)


    return (
        <div className="clientes" >

            <div className="title-clientes">
                <div className="content-clientes">
                    <h1>Nossos Clientes</h1>
                </div>
           </div>

            {/*Lines*/}

            <div className="container-slider-clientes">

                <Line1 tempo={tempo} />
                
                <Line2 tempo={tempo} />

            </div>

        </div>
    )
}

export default Clientes;
