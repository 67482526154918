import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Portal = () => {
    return (
        <div className="portal" >
            <div className="content-portal">
            

                <div className="content-portal-title">
                    <div className="title-portal">
                        <div className="content-title-portal">
                            <h1>Portal do Contador</h1>
                            <p>
                                Seus clientes integrando documentos fiscais em um só lugar. <br />
                                Produtividade para o contador!
                            </p>
                        </div>
                    </div>
                </div>

                <div className="content-vantagens-portal">
                    <div className="vantagens-portal">

                        <div className="content-title-vantagens-portal">

                        <div className="title-vantagens-content-portal">
                       <div className="content-img-title-vantagens-portal">
                       <img className="img-vantagem-portal" src="https://res.cloudinary.com/kassio-company/image/upload/v1633977607/E-Tech/Diversos/05_wj1exq.png" alt="" />
                       </div>

<div className="title-vantagens-portal">
    <h1>Um produto E-Tech Sistemas para empresas</h1>
    <p>O Portal do contador foi idealizado pela E-Tech a fim de levar inovação e facilidades para todos os contadores. </p>
</div>
</div>
                        </div>

                        <div className="content-box-vantagem-portal">
                            <div className="content-box-vantagem-portal">

                            <div className="box-vantagem-portal">
                                <i class="fas fa-file-alt"></i>
                                <div className="title-box-vantagem-portal">
                                    <h1>Webservice</h1>
                                    <p>Webservice para recebimento de XML de documentos fiscais.</p>
                                </div>
                            </div>

                            <div className="box-vantagem-portal">
                                <i class="fas fa-file-invoice"></i>
                                <div className="title-box-vantagem-portal">
                                    <h1>Informações Fiscais</h1>
                                    <p>Trata e extrai informações fiscais dos XMLs.</p>
                                </div>
                            </div>

                            <div className="box-vantagem-portal">
                                <i class="fas fa-file-download"></i>
                                <div className="title-box-vantagem-portal">
                                    <h1>XMLs para Download</h1>
                                    <p>Disponibiliza os XMLs para download aos contadores, além de resumos fiscais baseados nas operações informadas nos XMLs</p>
                                </div>
                            </div>

                            </div>

                            <div className="border-portal"></div>

                            <div className="benefecios-portal">
                                <div className="content-beneficios-portal">
                                    
                                    <div className="box-benefecios-portal">
                                        <div className="content-box-beneficios-portal">
                                            <h1>Armazenamento dos XMLs</h1>
                                            <p>Armazena e organiza os arquivos fiscais facilitando sua disponibilidade.</p>
                                        </div>
                                    </div>

                                    <div className="box-benefecios-portal">
                                        <div className="content-box-beneficios-portal">
                                            <h1>Totalizadores</h1>
                                            <p>Totalizadores por CFOP e CST ICMS/IPI/PIS/COFINS.</p>
                                        </div>
                                    </div>

                                    <div className="box-benefecios-portal">
                                        <div className="content-box-beneficios-portal">
                                            <h1>ICMS ST</h1>
                                            <p>Demonstrativo de ICMS-ST das operações entrada x saída.</p>
                                        </div>
                                    </div>

                                    <div className="box-benefecios-portal">
                                        <div className="content-box-beneficios-portal">
                                            <h1>Produtos</h1>
                                            <p>Agrupamento de produtos das operações fiscais.</p>
                                        </div>
                                    </div>

                                </div>

                                <img src="https://res.cloudinary.com/kassio-company/image/upload/v1633977607/E-Tech/Diversos/03_t74caa.png" alt="Icone E-Tech" />
                            </div>

                            <div className="border-portal"></div>
                            

                            <div className="logos-sistemas-portal">
                                <h1>Alguns sistemas compatíveis</h1>
                                <div className="content-sistemas-portal">
                                    <div className="content-logos-sistemas-portal">
                                        <div className="box-logo-sistema">
                                            <img src="https://res.cloudinary.com/kassio-company/image/upload/v1634237025/E-Tech/Empresas/Hotline-2.png" alt="Host da Hotline" />
                                        </div>

                                        <div className="box-logo-sistema">
                                            <img src="https://res.cloudinary.com/kassio-company/image/upload/v1634236886/E-Tech/Empresas/Compufour-2.jpg" alt="Compufour" />
                                        </div>

                                        <div className="box-logo-sistema">
                                            <img src="https://res.cloudinary.com/kassio-company/image/upload/v1634236990/E-Tech/Empresas/Gdoor-2.jpg" alt="Gdoor" />
                                        </div>

                                        <div className="box-logo-sistema">
                                            <img src="https://res.cloudinary.com/kassio-company/image/upload/v1628526080/E-Tech/Empresas/Sgbr_kdeozf.png" alt="SGBR" />
                                        </div>

                                        <div className="box-logo-sistema">
                                            <img src="https://res.cloudinary.com/kassio-company/image/upload/v1633974254/E-Tech/Empresas/SmallSoft.png" alt="SmallSoft" />
                                        </div>

                                        <div className="box-logo-sistema">
                                            <img src="https://res.cloudinary.com/kassio-company/image/upload/v1633974301/E-Tech/Empresas/Digisat.png" alt="Digisat" />
                                        </div>

                                        <div className="box-logo-sistema">
                                            <img src="https://res.cloudinary.com/kassio-company/image/upload/v1633974475/E-Tech/Empresas/ElloMaster.png" alt="ElloMaster" />
                                        </div>

                                        <div className="box-logo-sistema">
                                            <img src="https://res.cloudinary.com/kassio-company/image/upload/v1633974520/E-Tech/Empresas/NetSoftware.png" alt="Netsoft" />
                                        </div>
                                    </div>
                                <img className="img-sistema-portal" src="https://res.cloudinary.com/kassio-company/image/upload/v1633977607/E-Tech/Diversos/04_qy5opx.png" alt="" />
                                </div>
                            </div>

                        </div>

                        <div className="border-portal"></div>

                            <div className="about-portal">
                                <div className="content-about-portal">
                                    <h1>Sobre o Portal do Contador</h1>
                                    <div className="descricao-about-portal">
                                        <p>
                                            O Portal faz comunicação com a estação de trabalho da empresa varrendo os documentos Xml’s tratados pelo sistema. 
                                            Esses arquivos são enviados para o Portal para serem processados e disponibilizados aos contadores parceiros. 
                                            Dentro do Portal será possível extrair os arquivos XML’s bem como os relatórios de competência. 
                                        </p>
                                    </div>
                                    <div className="content-button-portal">
                                        <div className="button-portal">
                                            <a href="/Solicitar" >Solicitar Agora</a>
                                            <i class="fas fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-img-about-portal">
                                    <img src="https://res.cloudinary.com/kassio-company/image/upload/v1633977607/E-Tech/Diversos/01_oihkwe.png" alt="Icone E-Tech" />
                                </div>
                            </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Portal
