import React from 'react';
import ReactDOM from 'react-dom';

import Root from './root';
import './global.css';


ReactDOM.render(
    <Root />,
  document.getElementById('root')
);
