import React, { useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";

const Contato = () => {

  const [motivo, setMotivo] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [descricao, setDescricao] = useState('');

  const alert = useAlert()

  document.body.scrollTop = 0;

  return (
      <div className="contato" >
        <div className="content-contato">
          
          <div className="box-title-contato">
            <i className="fas fa-laugh-beam"></i>
            <p>Olá, tudo bem? para entrar em contato basta preencher o formulário e selecionar o meio de envio .</p>
          </div>

          <div className="input-contanto">

            <div className="content-info-contato">
              <div className="box-content-info-contato">
                  <i className="fas fa-phone"></i>
                  <div className="info-contato">
                    <h1>Ligue</h1>
                    <p>(75) 3331 3626</p>
                  </div>
                </div>

                <div className="box-content-info-contato">
                  <i className="fas fa-envelope"></i>
                  <div className="info-contato">
                    <h1>E-Mail</h1>
                    <p>contato@etechseabra.com.br</p>
                  </div>
                </div>

                <div className="box-content-info-contato">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="info-contato">
                    <h1>Localização</h1>
                    <p>Rua Simpliciano Lima, 481-B, <br /> Seabra - BA, Centro, Sala 1</p>
                  </div>
                </div>
            </div>
            
            <div className="content-input-contato">
              <form>

                <input onChange={(e) => setMotivo(e.target.value)} type="text" name="motivo" placeholder="Motivo" required />
          
                <input onChange={(e) => setName(e.target.value)} type="text" name="name" placeholder="Nome" required />

                <input onChange={(e) => setEmail(e.target.value)} type="email" name="email" placeholder="E-mail" required />

                <textarea onChange={(e) => setDescricao(e.target.value)}  placeholder="Descrição" name="descricao" cols="30" rows="5" required></textarea>

                <div className="content-button-contato">
              <div className="buttonContato" id="buttonContatoSolicitar-whats">
                <i class="fab fa-whatsapp"></i>
                {descricao === '' ? <Link to="#" onClick={() => alert.show("Preencha o formulário corretamente")} >Enviar Whatsapp</Link>  :  <a href={`https://api.whatsapp.com/send?phone=5575999553626&text=Motivo: ${motivo}%0ANome: ${name}%0AE-Mail: ${email}%0ADescrição: ${descricao}`} target="_blank" >Enviar Whatsapp</a> }
              </div>

              <div className="buttonContato" id="buttonContatoSolicitar-gmail">
                <i class="far fa-envelope"></i>
                {descricao === '' ? <Link to="#" onClick={() => alert.show("Preencha o formulário corretamente")} >Enviar E-Mail</Link> : <a href={`https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=${motivo}&to=contato@etechseabra.com.br&body=Obs: ${descricao}`} target="_blank" >Enviar E-Mail</a>}
              </div>
            </div>

              </form>
            </div>

          </div>
        </div>
    </div> 
    )
}

export default Contato;