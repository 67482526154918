import React from 'react';
import { Link } from 'react-router-dom';

const Vendas = () => {

  document.body.scrollTop = 0;

  return (
    <div>
      
      <div className="vendas">

      <div className="contentVendas">
          <div className="contentBoxTitle">
            <div className="boxTitle">
                <div className="contentBoxTitleVendas">
                    <div className="boxTitleContent">
                        <h1>Sobre nós</h1>
                    </div>
                    <div className="titleVendas">
                      <p>Veja algumas informações interessantes sobre a nossa empresa</p>
                    </div>
                </div>
            </div>
          </div>
        </div>

          <div className="content-box-vendas">

          <div className="box-vendas">
              <div className="content-box-venda">

                <i class="fas fa-globe"></i>

                  <p>Ultilizamos tecnologia de ponta para distribuição das soluções e produtos</p>

                  <div className="content-button-vendas">
                    <div className="button-box-vendas">
                      <Link to="/Sobre">Sobre</Link>
                    </div>
                  </div>

                </div>
            </div>

            <div className="box-vendas">
              <div className="content-box-venda">

                  <img src="https://res.cloudinary.com/kassio-company/image/upload/v1629831284/E-Tech/Diversos/bahia.png" alt="Mapa da Bahia" />

                  <p>Atendemos toda Bahia com qualidade e eficiência</p>

                  <div className="content-button-vendas">
                    <div className="button-box-vendas">
                      <Link to="/Sobre">Sobre</Link>
                    </div>
                  </div>
                </div>
            </div>

            <div className="box-vendas">
              <div className="content-box-venda">

                  <i class="fas fa-shapes"></i>

                  <p>Somos parceiros das melhores empresas desse segmento</p>

                  <div className="content-button-vendas">
                    <div className="button-box-vendas">
                      <Link to="/Sobre">Sobre</Link>
                    </div>
                  </div>
                </div>
            </div>

          </div>



      </div>

      
            
      
    </div>
  );
}

export default Vendas;