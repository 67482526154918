import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  
  const [isMobile, setIsMobile] = useState(false);

  const [isMenu, setIsMenu] = useState(false);


  return (
    <div className="nav-page" >
      <nav className="navbar">

            <Link to="/" className="logo">
                <img src="https://res.cloudinary.com/kassio-company/image/upload/v1628526099/E-Tech/Logo.png" alt="Logo" />
            </Link>

            <ul className="nav-links" >

                <Link to="/" className="homeNavbar" >
                    <i class="fas fa-home-lg-alt mobileDisabled"></i>
                    <li>Home</li>
                </Link>

                <Link to="/Solicitar" className="button-ebackup" >
                    <div className="boxPortal">
                      <li>Solicitar agora!</li>
                    </div>
                </Link>

                <a href="https://us-cloud.acronis.com/login" target="_blanck" >
                    <div className="boxLoginNavbar">
                       <li>Login</li>
                    </div>
                </a>

            </ul>

            <button className="mobile-menu-icon" onClick={() => {setIsMobile(!isMobile)}}>
                {isMobile ? (
                <i class="fas fa-times"></i>
                ) : (
                <i class="fas fa-bars"></i>
                )}
            </button>

        </nav>

          <ul className={isMobile ? "nav-links-mobile" : "disabled"}>
          <div className="content-menu-mobile">


            <Link to="/" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-home"></i>
              Home
              </li>
            </Link>

            <Link to="/Parceiros/Solucoes" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-desktop"></i>
              Soluções
              </li>
          </Link>
          
          <Link to="/Parceiros/Perifericos" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-print"></i>
              Periféricos
              </li>
          </Link>
          
          <Link to="/Parceiros/Certificado" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-certificate"></i>
              Certificados
              </li>
          </Link>
          
          <Link to="/Sobre" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-user"></i>
              Sobre
              </li>
          </Link>

          <Link to="/Solicitar" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-comments-dollar"></i>
              Solicitar
              </li>
            </Link>
          
          <Link to="/Contato" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-envelope"></i>
              Contato
              </li>
            </Link>

            <a href="https://us-cloud.acronis.com/login" target="_blanck" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-cloud"></i>
                E - Backup
                </li>
          </a>
          
          <Link to="/Portal" target="_blanck" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-portrait"></i>
              Portal do Contador
              </li>
            </Link>

            <Link to="/Login" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile">
              <i class="fas fa-user"></i>
              Login
              </li>
            </Link>

            </div>
          </ul>

          
              
        </div>
  );

}

export default Navbar;