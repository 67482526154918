import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {

    document.body.scrollTop = 0;

    return (
        <div className="not-found">
            <div className="content-not-found">
                <h1>404</h1>
                <h2>Até que enfim você apareceu né?</h2>
                <p>Parece que você se perdeu, volte imediatamente para a <Link to="/" >página inicial!</Link></p>
                <div className="content-button-not-found">
                    <div className="button-not-found">
                        <Link to="/" >
                            Voltar para página inicial
                        </Link>
                    </div>
                </div>

                <div className="animation-notfound">
                    <i class="fas fa-car-side"></i>
                    <div className="pista-animation">
                        <div className="tracos-pista-animation-1"></div>
                        <div className="tracos-pista-animation-2"></div>
                        <div className="tracos-pista-animation-3"></div>
                        <div className="tracos-pista-animation-4"></div>
                        <div className="tracos-pista-animation-5"></div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default NotFound
