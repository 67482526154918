import React, {useEffect} from 'react';

const About = () => {

    return (
      <div>
        <div className="about" id="about">
          

            <div className="contentAbout">
                  <div className="titleAbout">
                        <div className="contentTitleAbout">
                          <h1>Quem somos?</h1>
                          <p>A E-tech é uma empresa que fornece soluções para a gestão de
                          negócios, que vão desde a emissão de documentos fiscais e venda de
                          produtos de informática, até o controle total de uma organização, por
                          exemplo. Isso ocorre através do entendimento da necessidade do cliente
                          e oferecimento do produto e serviço voltado para a demanda, atuando de
                          forma adjacente ao cliente e levando, sempre, os valores da empresa aos
                          trabalhos realizados, de forma séria, criativa, respeitosa, profissional e
                          moderna.</p>
                        </div>
                    </div>

                    <div className="destaquesAbout">
                      <div className="borderAbout"></div>

                      <div className="destaquesBox">
                        <div className="contentDestaquesBox">
                          <div className="boxNumberDestaques">
                            <h1>1</h1>
                          </div>
                          <p>Criativa</p>
                        </div>

                        <div className="contentDestaquesBox">
                          <div className="boxNumberDestaques">
                            <h1>2</h1>
                          </div>
                          <p>Respeitosa</p>
                        </div>

                        <div className="contentDestaquesBox">
                          <div className="boxNumberDestaques">
                            <h1>3</h1>
                          </div>
                          <p>Profissional</p>
                        </div>

                        <div className="contentDestaquesBox">
                          <div className="boxNumberDestaques">
                            <h1>4</h1>
                          </div>
                          <p>Moderna</p>
                        </div>

                        <div className="contentDestaquesBox">
                          <div className="boxNumberDestaques">
                            <h1>5</h1>
                          </div>
                          <p>Séria</p>
                        </div>
                      </div>

                    </div>
            </div>
        
          </div>
      </div>
    );
  }

export default About;