import React from 'react';
import { Link } from 'react-router-dom';

import Imagem1 from '../img/1.jpg';
import Imagem2 from '../img/2.jpg';
import Imagem3 from '../img/3.jpg';
import Imagem4 from '../img/4.jpg';
import Imagem5 from '../img/5.jpg';
import Imagem6 from '../img/6.jpg';

const Novidades = () => {

    document.body.scrollTop = 0;

    return (
        <div className="novidades" >
            <div className="content-novidades">

                <h1>Nosso Instagram</h1>
      
                <div style={{ width: '100%'}}>
                    <div className="content-box-insta" >

                        <div className="box-insta">
                            <img src={Imagem1} />
                            <div className="content-box-insta-center">
                                <a href="https://www.instagram.com/p/CWDxu2_r9sk/" target="_blank" className="content-button-insta">Ver Publicação</a>
                            </div>
                        </div>

                        <div className="box-insta">
                            <img src={Imagem3} />
                            <div className="content-box-insta-center">
                                <a href="https://www.instagram.com/p/CV3Ry8VJXb5/" target="_blank" className="content-button-insta">Ver Publicação</a>
                            </div>
                        </div>

                        <div className="box-insta">
                            <img src={Imagem6} />
                            <div className="content-box-insta-center">
                                <a href="https://www.instagram.com/p/CVu7oBlrsPc/" target="_blank" className="content-button-insta">Ver Publicação</a>
                            </div>
                        </div>

                        <div className="box-insta">
                            <img src={Imagem4} />
                            <div className="content-box-insta-center">
                                <a href="https://www.instagram.com/p/CVAy4RMLQW_/" target="_blank" className="content-button-insta">Ver Publicação</a>
                            </div>
                        </div>

                        <div className="box-insta">
                            <img src={Imagem2} />
                            <div className="content-box-insta-center">
                                <a href="https://www.instagram.com/p/CUxNRO3rCoW/" target="_blank" className="content-button-insta">Ver Publicação</a>
                            </div>
                        </div>

                        <div className="box-insta">
                            <img src={Imagem5} />
                            <div className="content-box-insta-center">
                                <a href="https://www.instagram.com/p/CUYHG1hpbYT/" target="_blank" className="content-button-insta">Ver Publicação</a>
                            </div>
                        </div>

                    </div>
                </div>

                
            </div>
        </div>
    )
}

export default Novidades
