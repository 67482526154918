import React from 'react';
import { Link } from 'react-router-dom';

const Solucoes = () => {
    return (
        <div className="parceiros" id="parceiros" >

      {/*Soluções*/}

      <div className="contentParceiros">

          <div className="classesParceiros">
          <i class="fas fa-desktop"></i>
          <h1>Soluções</h1>
          </div>
          

        <div className="contentBoxParceiro">

          <div className="parteBoxParceiro">
            <div className="boxParceiros" id="boxHotline">
              <Link to="/Empresas/Hotline">
              <img src="https://res.cloudinary.com/kassio-company/image/upload/v1628526079/E-Tech/Empresas/HotlineRoxo_xeaqx5.jpg" alt="Hotline" />
              </Link>
            </div>
  
            <div className="boxParceiros" id="boxGdoor">
              <Link to="/Empresas/Gdoor">
              
              <img src="https://res.cloudinary.com/kassio-company/image/upload/v1628526079/E-Tech/Empresas/Gdoor_luvk8y.png" alt="Gdoor" />
              </Link>
            </div>
  
            <div className="boxParceiros" id="boxSgbr">
              <Link to="/Empresas/Sgbr">
              
                <div className="sgbrParceiros">
                  <img src="https://res.cloudinary.com/kassio-company/image/upload/v1628526080/E-Tech/Empresas/Sgbr_kdeozf.png" alt="Sgbr" />
                </div>
              </Link>
            </div>
  
            <div className="boxParceiros" id="boxCompufour">
              <Link to="/Empresas/Compufour">
              
              <img src="https://res.cloudinary.com/kassio-company/image/upload/v1628526079/E-Tech/Empresas/Compufour_han1ls.png" alt="Compufour" />

              </Link>
            </div>
              
            </div>
          </div>
          
          <div className="content-prox-parceiros">
            <div className="prox-parceiros-button">
              <Link to="/Parceiros/Perifericos" >
                Periféricos
              </Link>
              <i class="fas fa-chevron-right"></i>
              </div>
          </div>
          
          </div>
        </div>
    )
}

export default Solucoes
