import React from 'react'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import img2 from '../img/img2.gif';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Header = () => {

    return (
        <div className="slider">
            <div className="sliderPC">
                <AutoplaySlider className="sliderImg"  play={true} cancelOnInteraction={false} interval={7000}>
                    <div data-src="https://res.cloudinary.com/kassio-company/image/upload/v1628526134/E-Tech/Slider/Pc/img1.png" />
                    <div data-src={img2} />
                    <div data-src="https://res.cloudinary.com/kassio-company/image/upload/v1628526982/E-Tech/Slider/Pc/img3.png" />
                    <div data-src="https://res.cloudinary.com/kassio-company/image/upload/v1628526135/E-Tech/Slider/Pc/img4.png" />
                </AutoplaySlider>
            </div>

            <div className="sliderMobile">
                <AutoplaySlider className="sliderImg" play={true} cancelOnInteraction={false} interval={7000} >
                    <div data-src="https://res.cloudinary.com/kassio-company/image/upload/v1628526134/E-Tech/Slider/Mobile/img1.png" />
                    <div data-src="https://res.cloudinary.com/kassio-company/image/upload/v1632069233/E-Tech/Slider/Mobile/img2.gif" />
                    <div data-src="https://res.cloudinary.com/kassio-company/image/upload/v1628526982/E-Tech/Slider/Mobile/img3.png" />
                    <div data-src="https://res.cloudinary.com/kassio-company/image/upload/v1628526135/E-Tech/Slider/Mobile/img4.png" />
                </AutoplaySlider>
            </div>
        </div>
    )
}

export default Header;