import React from 'react';
import { Link } from 'react-router-dom';

const Certificado = () => {
    return (
        <div className="parceiros" >
            <div className="contentParceiros">

          <div className="classesParceiros">
          <i class="fas fa-certificate"></i>
  <h1>Certificados Digitais</h1>
</div>

<div className="contentBoxParceiro">

  <div className="parteBoxParceiro">
    <div className="boxParceiros">
      <Link to="Empresas/Compufour">
        <h1>e-CPF</h1>
      </Link>
    </div>

    <div className="boxParceiros">
      <Link to="Empresas/Bematech">
        <h1>e-CNPJ</h1>
      </Link>
    </div>

    <div className="boxParceiros">
      <Link to="Empresas/Gdoor">
        <h1>e-NFE</h1>
      </Link>
    </div>

    <div className="boxParceiros">
      <Link to="Empresas/Digisat">
        <h1>OAB</h1>
      </Link>
    </div>
    
  </div>

  
          </div>
          
          <div className="content-prox-parceiros">
            <div className="prox-parceiros-button">
                <Link to="/Parceiros/Solucoes" >
                Soluções
              </Link>
              <i class="fas fa-chevron-right"></i>
              </div>
          </div>
                </div>
        </div>
    )
}

export default Certificado
