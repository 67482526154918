import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Details = () => {

    const [ blog, setBlog ] = useState([]);

    useEffect(() => {
        axios.get(`https://api-etech.herokuapp.com${window.location.pathname}`).then(({data}) => {
            setBlog(data);
          })
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="details" >
            <div className="content-details">

                <h1>{blog.title}</h1>

            </div>
        </div>
    )
}

export default Details
