import React from "react";
import { Link } from "react-router-dom";

const Compufuor = () => {
    return (
        <div>

<div className="Info">
        <div className="contentInfo">
            <div className="sobreInfo">
                <div className="boxInfo">
                    <div className="infoboxInfo">
                        <h1>Sobre o Compufour</h1>
    
                        <p>Fundada em 1995 e inicialmente chamando-se CompuTop Informática, a empresa voltava-se para o desenvolvimento de sistemas em diversas áreas e também de automação comercial para empresas específicas, instalação de redes, venda de computadores e equipamentos, bem como sua manutenção.
                            <br />
                            Atualmente a Compufour é considerada uma das principais desenvolvedoras de software para micros e pequenas empresas, contando com mais de 2.000 parceiros revendedores ativos e mais de 50.000 clientes usuários ativos. Todo esse crescimento só foi possível devido ao compromisso na qualidade de seus produtos e, principalmente, visando à satisfação dos clientes e colaboradores.
                        </p>
                    </div>
                </div>
            </div>

            <div className="imgInfo">
                <div className="imgInfo-01">
                    <img src="https://www.compufour.com.br/arquivos/images/estrutura/01.JPG" alt="Imagem Compufour" />
                </div>
    
                <div className="imgInfo-02">
                    <img src="https://www.compufour.com.br/arquivos/images/estrutura/03.JPG" alt="Imagem Compufour 2" />
                </div>
            </div>
        </div>

        <div className="videoInfo">
            <iframe width="700" height="450" src="https://www.youtube.com/embed/DAd5qfgNx9g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div className="infoVideo">
            <div className="boxInfoVideo">

                <h1>Mais informações</h1>

                <p>Atuando nos municípios do oeste Catarinense e sonhando alto, lutando contra a falta de tradição no desenvolvimento de softwares que Concórdia possuía, o foco da empresa foi alterado, surgindo assim a CompuFour Software – empresa voltada para o desenvolvimento de sistemas para gerenciamento de micros e pequenas empresas do comércio para todo o território nacional.


                    No ano de 1996 a empresa deu um dos passos mais importantes em sua história: sendo a única empresa representante do Oeste Catarinense a participar da Feira Nacional do Software - FENASOFT, lançando-se no mercado nacional. A partir daí, o reconhecimento da empresa foi crescendo cada vez mais.</p>
            </div>
        </div>

        <div className="infoTutorial">
            <div className="boxInfoTutorial">
                <h1>Quer dar uma aprofundada maior nos progamas do Compufour ? <br />

                <Link href="/Dashboard/Download" to="/Dashboard/Download">
                    <a>Veja alguns tutorias</a>
                </Link>
                    
                </h1>

                <div className="tutorialInfo">
                    <div className="videoTutoriaInfo-1">
                        <iframe width="350" height="250" src="https://www.youtube.com/embed/XBxjgyN0IA4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>

                    <div className="videoTutoriaInfo-2">
                        <iframe width="350" height="250" src="https://www.youtube.com/embed/_07Pxj8aVHA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>

                <div className="buttonTutorialInfo">
                    <i className="fas fa-eye"></i>
                    <Link href="/Dashboard/Download" to="/Dashboard/Download">
                    <a>Acessar tutoriais</a>
                    </Link>
                </div>
            </div>
        </div>

        <div className="infoSolicitar">
            <div className="boxinfoSolicitar">
                <div className="infoSolicitarAgora">
                    <i className="fas fa-shopping-cart"></i>
                    <Link to="/Solicitar">
                            <a>Solicitar agora</a>
                            </Link>
                </div>
            </div>
        </div>
    </div>

        </div>
    )
}

export default Compufuor;