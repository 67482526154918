import React from 'react'
import { Link } from 'react-router-dom';

  
const Principal = () => {

    document.body.scrollTop = 0;
    
        return (
            <div className="principal" >
                <div className="content-principal">
                    <div className="title-content-principal">
                        <p>Principal Solução :</p>
                        <h1>Host da Hotline Tecnologia</h1>
                    </div>

                    <div className="content-box-videos-principal" id="pc">

                        <div className="box-videos-principal">
                            <iframe src="https://www.youtube.com/embed/DsBbnQyu_pE" className="iframe-principal-1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>

                        <div className="box-videos-principal">
                            <iframe src="https://www.youtube.com/embed/ooYYs8vdnJo" className="iframe-principal-2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>

                        <div className="box-videos-principal">
                            <iframe src="https://www.youtube.com/embed/xJdJcfEUHjI" className="iframe-principal-3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        
                    </div>

                    <div className="content-box-videos-principal-mobile" id="mobile">

                        <div className="box-videos-principal-mobile">
                            <iframe src="https://www.youtube.com/embed/DsBbnQyu_pE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        
                        <div className="box-videos-principal-mobile">
                            <iframe src="https://www.youtube.com/embed/xJdJcfEUHjI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>

                    </div>

                    <div className="content-text-principal">
                        <p className="text-principal" >Um sistema com todas as funcionalidades que o seu negócio precisa!</p>
                   </div>
                    
                    <div className="vantagens-principal">

                        <div className="title-vantagens">
                            <h1>Vantagens :</h1>
                        </div>

                        <div className="box-vantagens-principais">
                            <div className="classe-principal">
                                <h1>Vendas</h1>

                                <div className="vantagem-principal">
                                    <div className="content-vantagem-principal">
                                    <i class="fas fa-check-circle"></i>
                                        <p>Frente de caixa – PDV</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Força de vendas</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Orçamento de venda</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Integração com balança</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Boleto bancário</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Confissão de dívida 80mm</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Nota promissória 80mm</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Nota fiscal Eletrônica – NFe</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Emissão de NFCe</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Emissão de SAT</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Emissão de Manifesto de carga – MDFe</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="box-vantagens-principais">
                            <div className="classe-principal">
                                <h1>Estoque</h1>

                                <div className="vantagem-principal">
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Controle de estoque</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Movimentação do produto</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Ajuste de preço em massa</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Encarte promocional</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Ajuste de tributação em massa</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Inventário</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Composição</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Gestão financeira</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="box-vantagens-principais">
                            <div className="classe-principal">
                                <h1>Gestão Financeira</h1>

                                <div className="vantagem-principal">
                                <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Cobrança bancário/carteira</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Demonstrativo de Resultados (DRE)</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Gráfico de Resultados</p>
                                    </div>
                                    
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Controle de comissões</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Contas a pagar e receber</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Relatório de Cancelamentos</p>
                                    </div>
                                    <div className="content-vantagem-principal">
                                        <i class="fas fa-check-circle"></i>
                                        <p>Relatório de Descontos</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="content-button-principal">
                        <div className="button-principal">
                            <Link to="/Solicitar" >
                                Solicitar um teste grátis!
                            </Link>
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        )
   
}

export default Principal
