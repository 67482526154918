import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { IoMdArrowDropdown } from 'react-icons/io';

const Navbar = () => {
  
  const [isMobile, setIsMobile] = useState(false);

  const [isMenu, setIsMenu] = useState(false);

  document.body.scrollTop = 0;


  return (
    <div className="nav-page" >
      <nav className="navbar">

            <Link to="/" className="logo">
                <img src="https://res.cloudinary.com/kassio-company/image/upload/v1628526099/E-Tech/Logo.png" alt="Logo" />
            </Link>

            <ul className="nav-links" >

                <Link to="/" className="homeNavbar" >
                    <i class="fas fa-home-lg-alt mobileDisabled"></i>
                    <li>Home</li>
                </Link>

                <div className="explorarMenu">

            <div className="menuNavbar" onClick={() => { setIsMenu(!isMenu) }} >
                    <li>Explorar</li>
                    <IoMdArrowDropdown />
                  </div>

                </div>

                <Link to="/Instagram" className="button-ebackup" >
                    <div className="boxEbackup">
                      <li>Instagram</li>
                    </div>
          </Link>
               

                <Link to="/Ebackup" className="button-ebackup" >
                    <div className="boxEbackup">
                      <li>E - Backup</li>
                    </div>
          </Link>
          
                <Link to="/Portal" className="button-portalcontador" >
                    <div className="boxPortal">
                      <li>Portal do Contador</li>
                    </div>
                </Link>

                {localStorage.getItem("token") ? <Link to="/Download">
                    <div className="boxLoginNavbar">
                       <li>Download</li>
                    </div>
                </Link> : <Link to="/Login">
                    <div className="boxLoginNavbar">
                       <li>Login</li>
                    </div>
                </Link>}

            </ul>

            <button className="mobile-menu-icon" onClick={() => {setIsMobile(!isMobile)}}>
                {isMobile ? (
                <i class="fas fa-times"></i>
                ) : (
                <i class="fas fa-bars"></i>
                )}
            </button>

        </nav>

        <div className={isMenu ? "menuOpenNavbar" : "menuCloseNavbar"} >

                    <div className="contentMenuOpenNavbar">

          <div className="contentMenuNavbar">
            
                      <div className="listMenuOpenNav">
                      <i class="fas fa-desktop"></i>
                        <Link to="/Parceiros/Solucoes" onClick={() => {setIsMenu(!isMenu)}} >
                          Soluções
                        </Link>
                      </div>

                      <div className="listMenuOpenNav">
                      <i class="fas fa-print"></i>
                        <Link to="/Parceiros/Perifericos" onClick={() => {setIsMenu(!isMenu)}} >
                          Periféricos
                        </Link>
                      </div>
            
                      <div className="listMenuOpenNav">
                      <i class="fas fa-certificate"></i>
                        <Link to="/Parceiros/Certificados" onClick={() => {setIsMenu(!isMenu)}} >
                          Certificado
                        </Link>
                    </div>
            
                    <div className="listMenuOpenNav">
                    <i class="fas fa-comments-dollar"></i>
                        <Link to="/Solicitar" onClick={() => {setIsMenu(!isMenu)}} >
                          Solicitar
                        </Link>
                      </div>
            
            
                      <div className="listMenuOpenNav">
                        <i class="fas fa-user"></i>
                        <Link to="/Sobre" onClick={() => {setIsMenu(!isMenu)}} >
                          Sobre
                        </Link>
                      </div>

                        <div className="listMenuOpenNav">
                          <i class="fas fa-envelope"></i>
                          <Link to="/Contato" onClick={() => {setIsMenu(!isMenu)}} >
                            Contato
                          </Link>
                        </div>

                        <div className="listMenuOpenNav">
                          <i class="fas fa-cloud"></i>
                          <Link to="/Login" onClick={() => {setIsMenu(!isMenu)}} >
                            Download
                          </Link>
                        </div>

                      </div>
                    </div>
          </div>

          <ul className={isMobile ? "nav-links-mobile" : "disabled"}>
          <div className="content-menu-mobile">


            <Link to="/" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-home"></i>
              Home
              </li>
            </Link>

            <Link to="/Parceiros/Solucoes" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-desktop"></i>
              Soluções
              </li>
          </Link>
          
          <Link to="/Parceiros/Perifericos" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-print"></i>
              Periféricos
              </li>
          </Link>
          
          <Link to="/Parceiros/Certificado" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-certificate"></i>
              Certificados
              </li>
          </Link>
          
          <Link to="/Sobre" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-user"></i>
              Sobre
              </li>
          </Link>

          <Link to="/Solicitar" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-comments-dollar"></i>
              Solicitar
              </li>
            </Link>
          
          <Link to="/Contato" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-envelope"></i>
              Contato
              </li>
            </Link>

            <Link to="/Ebackup" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-cloud"></i>
                E - Backup
                </li>
          </Link>
          
          <Link to="/Portal" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile" >
              <i class="fas fa-portrait"></i>
              Portal do Contador
              </li>
            </Link>

            <Link to="/Login" onClick={() => {setIsMobile(!isMobile)}} >
              <li className="link-mobile">
              <i class="fas fa-user"></i>
              Login
              </li>
            </Link>

            </div>
          </ul>

          
              
        </div>
  );

}

export default Navbar;