import React from "react";
import { Link } from "react-router-dom";

const Hotline = () => {
    return (
        <div>

<div className="Info">
        <div className="contentInfo">
            <div className="sobreInfo">
                <div className="boxInfo">
                    <div className="infoboxInfo">
                        <h1>Sobre a Hotline</h1>
    
                        <p>A Hotline Tecnologia surgiu em 2017 com o propósito de transformar o mercado de Sistemas de Automação Comercial. Seu fundador, atuou como uma Revenda das maiores empresas do mercado por 5 anos, sentiu na pele a opressão da política comercial. Seu desenvolvedor atuava na linha de frente do suporte e conheceu a necessidade de cada parte (operador/técnico/proprietário). Com todos esses aprendizados, surgiu um sistema rápido, prático e benéfico para todos.
                        A Hotline Tecnologia sabe que o crescimento da Softhouse, depende exclusivamente do sucesso da revenda. Por esta razão, criou uma política comercial amigável. 
                        </p>
                    </div>
                </div>
            </div>

            <div className="imgInfo">
                <div className="imgInfo-01">
                    <img src="http://images.tcdn.com.br/img/img_prod/726234/hotline_tecnologia_sistema_de_automacao_comercial_host_17_3_20191012210340.jpg" alt="Imagem Hotline" />
                </div>
    
                <div className="imgInfo-02">
                    <img src="https://hotlinetecnologia.com.br/img/sistema.png" alt="Imagem Hotline" />
                </div>
            </div>
        </div>

        <div className="videoInfo">
        <iframe width="700" height="450" src="https://www.youtube.com/embed/DsBbnQyu_pE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div className="infoVideo">
            <div className="boxInfoVideo">

                <h1>Mais informações</h1>

                <p>Hoje, o sistema está presente em 25 estados da federação, com mais de 13.000 unidades rodando com sucesso, sem aqueles milhões de chamados abertos e nunca resolvidos.

Investimos na capacitação de nossa equipe, visando dar um melhor suporte técnico e atendimento aos nossos parceiros.
A Hotline investe na capacitação de nossa equipe, visando dar um melhor suporte técnico e atendimento a nossos parceiros. Também está investindo em novas tecnologias, como fórum da Hotline e novo site.</p>
            </div>
        </div>

        <div className="infoTutorial">
            <div className="boxInfoTutorial">
                <h1>Quer dar uma aprofundada maior nos progamas da Hotline ? <br />
                <Link href="/Dashboard/Download" to="/Dashboard/Download">
                    <a>Veja alguns tutorias</a>
                </Link>
                    </h1>

                <div className="tutorialInfo">
                    <div className="videoTutoriaInfo-1">
                        <iframe width="350" height="250" src="https://www.youtube.com/embed/YGdhX7TNjz0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>

                    <div className="videoTutoriaInfo-2">
                        <iframe width="350" height="250" src="https://www.youtube.com/embed/bzxpbaU6sFg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>

                <div className="buttonTutorialInfo">
                    <i className="fas fa-eye"></i>
                    <Link href="/Dashboard/Download" to="/Dashboard/Download">
                    <a>Acessar tutoriais</a>
                    </Link>
                </div>
            </div>
        </div>

        <div className="infoSolicitar">
            <div className="boxinfoSolicitar">
                <div className="infoSolicitarAgora">
                    <i className="fas fa-shopping-cart"></i>
                    <Link to="/Solicitar">
                            <a>Solicitar agora</a>
                            </Link>
                </div>
            </div>
        </div>
    </div>

        </div>
    )
}

export default Hotline;