import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import CookieConsent from "react-cookie-consent";
import { transitions, positions, Provider as AlertProvider  } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic';


//Pages

import Navbar from './pages/Navbar';
import Header from './pages/Slider';
import Clientes from './pages/Clientes/Clientes';
import About from './pages/About';
import Sobre from './pages/Sobre';
import Contato from './pages/Contato';
import Footer from './pages/Footer';
import Solicitar from './pages/Solicitar';
import Login from './pages/Login';
import Principal from './pages/Principal';
import Instagram from './pages/Instagram';
import Solucoes from './pages/Parceiros/Solucoes';
import Periferico from './pages/Parceiros/Periferico';
import Certificado from './pages/Parceiros/Certificado';
import Download from './pages/Download';

import Portal from './pages/Portal/Portal';
import NavPortal from './pages/Portal/Nav';
import SolicitarPortal from './pages/Portal/Solicitar';

import Ebackup from './pages/Ebackup/Ebackup';
import NavEbackup from './pages/Ebackup/Nav';
import SolcitarEbackup from './pages/Ebackup/Solicitar';

//Empresas

import SGBR from './pages/Empresas/sgbr';
import Compufuor from './pages/Empresas/compufour';
import Gdoor from './pages/Empresas/gdoor';
import Hotline from './pages/Empresas/hotline';
import NotFound from './pages/NotFound';  

import Blog from './pages/Blog/blog';
import Details from './pages/Blog/details';

import Popup from './pages/PopUp';

const Root = () => {

    const options = {
        position: positions.TOP_CENTER,
        timeout: 5000,
        offset: '20px',
        transition: transitions.FADE,
    }

    document.body.scrollTop = 0;

    return (
        <Router >
                <AlertProvider template={AlertTemplate} {...options}>

                        <Switch>

                    {/*Home*/}
                

                        <Route path="/" exact >

                            <Popup />
                        
                            <Navbar />
                            <Header />
                            <Clientes />
                            <Sobre />
                            <Principal />
                            <Instagram />

                        </Route>

                        {/*Blog*/}

                        <Route path="/Instagram" exact >
                            <Navbar />
                            <Instagram />
                        </Route>

                        <Route path="/Blog/:id" exact >
                            <Navbar />
                            <Details />
                        </Route>

                        {/*Login*/}

                        <Route path="/Login" exact >
                            <Navbar />
                            <Login />
                        </Route>

                        {/*Admin*/}
                        
                        <Route path="/Download" exact>
                            <Navbar />
                            <Download />
                        </Route>

                        {/*Contato*/}

                        <Route path="/Contato" exact>
                            <Navbar />
                            <Contato />
                        </Route>
                    
                        {/*About*/}

                        <Route path="/Sobre" exact >
                            <Navbar />
                            <About />
                        </Route>

                        {/*Solicitar*/}

                        <Route path="/Solicitar" exact >
                            <Navbar />
                            <Solicitar />
                        </Route>
                    
                        {/*Parceiros*/}

                        <Route path="/Parceiros/Solucoes" exact >
                            <Navbar />
                            <Solucoes />
                        </Route>

                        <Route path="/Parceiros/Perifericos" exact >
                            <Navbar />
                            <Periferico />
                        </Route>

                        <Route path="/Parceiros/Certificados" exact >
                            <Navbar />
                            <Certificado />
                        </Route>

                        {/*Portal do Contador*/}

                        <Route path="/Portal" exact >
                            <NavPortal />
                            <Portal />
                            <SolicitarPortal />
                        </Route>

                        {/*E-Backup*/}

                        <Route path="/Ebackup" exact >
                            <NavEbackup />
                            <Ebackup />
                            <SolcitarEbackup />
                        </Route>
                
                    
                        {/*Empresas*/}

                        <Route path="/Empresas/Sgbr" exact >
                            <Navbar />
                            <SGBR />
                        </Route>

                        <Route path="/Empresas/Compufour" exact >
                            <Navbar />
                            <Compufuor />
                        </Route>

                        <Route path="/Empresas/Gdoor" exact >
                            <Navbar />
                            <Gdoor />
                        </Route>

                        <Route path="/Empresas/Hotline" exact >
                            <Navbar />
                            <Hotline />
                        </Route>

                        <Route path="*" exact >
                            <Navbar />
                            <NotFound />
                        </Route>

                        </Switch>
                        
                        {/*Footer*/}

                        <Footer />

                        

                        {/*Cookies*/}

                        
                            <CookieConsent
                            location="bottom"
                            buttonText="Eu aceito!"
                            cookieName="cookies-site"
                            style={{ background: "#021338" }}
                            buttonStyle={{ color: "#021338", fontSize: "13px", background: "#fff", borderRadius: "15px"  }}
                            expires={false}
                            >
                           <span style={{ fontSize: "12px" }}>Este site ultiliza cookies, nós usamos ferramentas de terceiros para monitorar o tráfego de visitas e serviços deste site.</span>
                            </CookieConsent>
                    
                    </AlertProvider>
            </Router>
    )
}


export default Root;