import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FaTimes } from 'react-icons/fa';

const PopUp = () => {

    const [popUp, setPopUp] = useState(true);

    return (
        <div className="popup-home" >

            {
                popUp === true ?
                    
                    <Popup open position="center center">

                        <div className="popup-pc">
                            <div className="img-content-popup">

                                <img className="popup-home-img-pc" src="https://res.cloudinary.com/kassio-company/image/upload/v1635541423/E-Tech/PORTAL-DO-CONTADOR_w3oj51.png" alt="Portal do Contador" />

                            </div>

                            <div className="content-button-popup">
                                <div className="button-popup-home">
                                    <Link to="/Portal" >
                                        Visitar Portal
                                    </Link>
                                </div>
                                </div>

                                <div className="content-button-popup-2">
                                    <Link to="#" onClick={() => {setPopUp(false)}} >
                                        Continuar para a página inicial
                                    </Link>
                                </div>
                                
                            </div>

                        <div className="popup-mobile">
                            <div className="button-close-popup">
                                <FaTimes onClick={() => setPopUp(false)} />
                                </div>

                                <div className="img-content-popup">

                                <img className="popup-home-img-mobile" src="https://res.cloudinary.com/kassio-company/image/upload/v1635542403/E-Tech/STORIE_skh9lq.png" alt="Portal do Contador" />

                                </div>

                                <div className="content-button-popup">
                                <div className="button-popup-home">
                                    <Link to="/Portal" >
                                        Visitar Portal
                                    </Link>
                                </div>
                            </div>
                        </div>
                
                    </Popup>
                    : null
            }
        </div>
    )
}

export default PopUp
  